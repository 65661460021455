'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
var $ = require("jquery");
var _scroll_1 = require("./class/_scroll");
var _smooth_scroll_1 = require("./class/_smooth_scroll");
var _hamburger_1 = require("./class/_hamburger");
var _hamburger_fixed_1 = require("./class/_hamburger_fixed");
$(function () {
    new _scroll_1.default();
    new _smooth_scroll_1.default();
    new _hamburger_1.default();
    new _hamburger_fixed_1.default();
});
