'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Hamburger = void 0;
var $ = require("jquery");
var Hamburger = /** @class */ (function () {
    function Hamburger() {
        $('.trigHamburger').on('click', function () {
            var dataActive = $('.trigHamburger').attr('data-active');
            if (Number(dataActive) === 0) {
                $('.bulletMenu').attr('data-active', 1);
                $('.bulletNavi ').hide().fadeIn();
            }
            else {
                $('.bulletMenu').attr('data-active', 0);
                $('.bulletNavi ').fadeOut();
            }
        });
        $('.trigSeasonButton').on('click', function () {
            $('.bulletNavi ').fadeOut();
            $('.bulletMenu').attr('data-active', 0);
        });
    }
    return Hamburger;
}());
exports.Hamburger = Hamburger;
exports.default = Hamburger;
