'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Scroll = void 0;
var $ = require("jquery");
var Scroll = /** @class */ (function () {
    function Scroll() {
        var _this = this;
        this.buffer = 1000;
        this.setSeasonPosition();
        $(window).resize(function () {
            _this.setSeasonPosition();
        });
        $(window).scroll(function () {
            var scroll = $(window).scrollTop();
            var season = 'spring';
            if (scroll > _this.positionSummer - _this.buffer) {
                season = 'summer';
            }
            if (scroll > _this.positionAutumn - _this.buffer) {
                season = 'autumn';
            }
            if (scroll > _this.positionWinter - _this.buffer) {
                season = 'winter';
            }
            if (scroll > _this.positionContact - _this.buffer) {
                season = 'winter';
            }
            if (scroll > _this.positionSpContact - _this.buffer) {
                season = 'winter';
            }
            _this.changeSeason(season);
            _this.showContactButton(scroll);
        });
    }
    Scroll.prototype.setSeasonPosition = function () {
        this.positionSpring = $('.paramScrollSpring').offset().top;
        this.positionSummer = $('.paramScrollSummer').offset().top;
        this.positionAutumn = $('.paramScrollAutumn').offset().top;
        this.positionWinter = $('.paramScrollWinter').offset().top;
        this.positionContact = $('.paramScrollContact').offset().top;
        this.positionSpContact = $('.paramScrollSpContact').offset().top;
    };
    Scroll.prototype.changeSeason = function (season) {
        var spring = $('.bulletSeasonSpring');
        var summer = $('.bulletSeasonSummer');
        var autumn = $('.bulletSeasonAutumn');
        var winter = $('.bulletSeasonWinter');
        switch (season) {
            case 'spring':
                spring.attr('data-is-active', 'true');
                summer.attr('data-is-active', 'false');
                autumn.attr('data-is-active', 'false');
                winter.attr('data-is-active', 'false');
                break;
            case 'summer':
                spring.attr('data-is-active', 'false');
                summer.attr('data-is-active', 'true');
                autumn.attr('data-is-active', 'false');
                winter.attr('data-is-active', 'false');
                break;
            case 'autumn':
                spring.attr('data-is-active', 'false');
                summer.attr('data-is-active', 'false');
                autumn.attr('data-is-active', 'true');
                winter.attr('data-is-active', 'false');
                break;
            case 'winter':
                spring.attr('data-is-active', 'false');
                summer.attr('data-is-active', 'false');
                autumn.attr('data-is-active', 'false');
                winter.attr('data-is-active', 'true');
                break;
        }
    };
    Scroll.prototype.showContactButton = function (scroll) {
        if (scroll > 10) {
            $('.bulletFollowingContact').attr('data-is-show', 'true');
        }
        else {
            $('.bulletFollowingContact').attr('data-is-show', 'false');
        }
    };
    return Scroll;
}());
exports.Scroll = Scroll;
exports.default = Scroll;
