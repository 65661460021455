'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.SmoothScroll = void 0;
var $ = require("jquery");
var SmoothScroll = /** @class */ (function () {
    function SmoothScroll() {
        this.pcWidth = 1025;
        var self = this;
        $('a[href^="#"]').on('click', function () {
            var buffer = self.calcHeaderHeight();
            var speed = 400;
            var href = $(this).attr("href");
            var target = $(href == "#" || href == "" ? 'html' : href);
            var position = target.offset().top - buffer;
            $('body,html').animate({ scrollTop: position }, speed, 'swing');
            return false;
        });
    }
    SmoothScroll.prototype.calcHeaderHeight = function () {
        var browserWidth = $(window).outerWidth();
        var buffer = 67;
        if (browserWidth < this.pcWidth) {
            buffer = 0;
        }
        return buffer;
    };
    return SmoothScroll;
}());
exports.SmoothScroll = SmoothScroll;
exports.default = SmoothScroll;
