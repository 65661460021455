'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.HamburgerFixed = void 0;
var $ = require("jquery");
var HamburgerFixed = /** @class */ (function () {
    function HamburgerFixed() {
        $(function () {
            var isOpen = false;
            var position;
            $('.trigHamburger').click(function () {
                if (isOpen == false) {
                    position = $(window).scrollTop();
                    $('body').addClass('fixed').css({ 'top': -position });
                    isOpen = true;
                }
                else {
                    $('body').removeClass('fixed').css({ 'top': 0 });
                    window.scrollTo(0, position);
                    isOpen = false;
                }
            });
        });
    }
    return HamburgerFixed;
}());
exports.HamburgerFixed = HamburgerFixed;
exports.default = HamburgerFixed;
